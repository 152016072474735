/**
 * 生成随机数
 * @param {any} length
 * @returns {String}
 */
export function randomKey(length = 8) {
  const arr = '0123456789abcdefghijklmnopqrstuvwxyz';
  const len = arr.length;
  let str = '';
  for (let i = 0; i < length; i++) {
    const pos = Math.round(Math.random() * (len - 1));
    str += arr[pos];
  }
  return str;
}

/**
 * 简单对象序列化为字符串
 * @example {name: 'www', age: 30} => 'name=www&age=30'
 * @param {any} [data={}]
 * @returns {String | ''}
 */
export function serialize(data = {}) {
  return Object.keys(data)
    .filter((key) => ![null, '', undefined].includes(data[key]))
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

// 序列化（新增数组参数处理）
export function serializeDeep(data = {}) {
  return Object.keys(data)
    .filter((key) => ![null, '', undefined].includes(data[key]))
    .map((key) => {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        return data[key].map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    })
    .join('&');
}

/**
 * 过滤无效数据：null, '', [], {}
 * @param {Object} data
 * @returns {Object}
 */
export function filterInvalidData(data) {
  return Object.keys(data)
    .filter((key) => {
      if ([null, ''].includes(data[key])) {
        return true;
      }
      if (Array.isArray(data[key]) && !data[key].length) {
        return false;
      }
      if (JSON.stringify(data[key]) === '{}') {
        return false;
      }
      return true;
    })
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: data[key],
      }),
      {}
    );
}

// 去除前后空格
// demo = '  例子  ';  demo = trim(demo)
export function trim(str) {
  return str.replace(/^(\s|\xA0)+|(\s|\xA0)+$/g, '');
}

// 数值转为千分制
export function comdifySum(n) {
  return (Number(n).toFixed(2) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
}

// 字符串转数字
export function strToNum(str) {
  return str ? +str : '';
}

// 字符串数组转为数字数组
export function toNumArray(arr) {
  return arr ? arr.map((item) => +item) : arr;
}

// 过滤富文本标签
export function richTextFormate(value) {
  if (value.indexOf('<img') !== -1) {
    return value;
  }
  const richText = value.replace(/<[^>]+>/g, '').replace(/\s|[\r\n]|\↵/g, '') ? value : '';
  return richText;
}
