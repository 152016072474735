/**
 * 自定义错误类
 * @class FetchError
 * @extends {Error}
 */
class FetchError extends Error {
  constructor(error) {
    super();
    this.name = this.constructor.name;
    if (typeof error === 'string') {
      this.message = error;
    } else {
      this.code = error.code;
      this.message = error.message || error.msg;
      this.data = error.data;
      // this.stack = error.stack;
    }
  }
}

/**
 * ajax xhr
 * @description 异常处理
 * @param {any} { url, data: {}, uploadProgress, downloadProgress }
 * @returns {Promise}
 */
export function AjaxUpload({
  url,
  data = {},
  getXhr = () => {},
  uploadProgress = () => {},
  downloadProgress = () => {},
}) {
  if (!navigator.onLine) {
    throw new FetchError('网络异常');
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // xhr对象返回给调用者
    getXhr(xhr);

    // FormData数据处理
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
      fd.append(key, data[key]);
    });

    // 上传进度监听-数据传输进行中
    xhr.upload.addEventListener(
      'progress',
      function (ev) {
        if (ev.lengthComputable) {
          const percentage = Math.round((ev.loaded * 100) / ev.total);
          uploadProgress(percentage);
        }
      },
      false
    );

    // 保证上传进度显示确实达到了 100%（以防在上传过程中出现粒度误差）
    xhr.upload.addEventListener(
      'load',
      function (ev) {
        if (ev.lengthComputable) {
          const percentage = Math.round((ev.loaded * 100) / ev.total);
          uploadProgress(percentage);
        }
      },
      false
    );

    // 下载进度，在请求接收到数据的时候被周期性触发
    xhr.addEventListener('progress', function (ev) {
      // console.log('xhr download progress: ', ev);
      if (ev.lengthComputable) {
        const percentage = Math.round((ev.loaded * 100) / ev.total);
        downloadProgress(percentage);
      }
    });

    // 请求成功完成时触发
    xhr.addEventListener('load', function () {
      const res = JSON.parse(xhr.responseText);
      // 状态码处理
      if (res.code === 200) {
        resolve(res.data);
      } else {
        // 100009-未登录（登录超时）
        if (res.code === 100009) {
          ZP.quitSystem();
        }
        throw new FetchError(res);
      }
    });

    // 当请求被终止时触发（在load前触发有效）
    xhr.addEventListener('abort', function () {
      console.log('xhr abort');
      reject(new FetchError('取消上传'));
    });

    // 当请求遇到错误时，将触发error事件
    xhr.addEventListener('error', function () {
      console.log('xhr error');
      reject(new FetchError('上传出错'));
    });

    xhr.open('POST', `${ENV_CONFIG.url}${url}?t=${Date.now()}`, true);

    // 请求头配置(必须在 open之后send之前)
    xhr.setRequestHeader('X-SOURCE', 'WEB');

    // 发送请求数据
    xhr.send(fd);
  });
}
